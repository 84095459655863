export interface MobileImage {
  id: number;
  black: string;
  color: string;
  alt: string;
  width: number;
  height: number;
  active: boolean;
}

const imageHeight = 422;

export const mobileImages: MobileImage[] = [
  {
    id: 1,
    black: "01-SmallBlack.png",
    color: "01-SmallColor.png",
    alt: "web page, graphic design, travel, one page, parallax",
    width: 633,
    height: imageHeight,
    active: false,
  },
  {
    id: 2,
    black: "02-SmallBlack.png",
    color: "02-SmallColor.png",
    alt: "graphic design, logo, design, art, organic",
    width: 317,
    height: imageHeight,
    active: false,
  },
  {
    id: 3,
    black: "03-SmallBlack.png",
    color: "03-SmallColor.png",
    alt: "Instagram posts",
    width: 1029,
    height: imageHeight,
    active: false,
  },
  {
    id: 4,
    black: "04-SmallBlack.png",
    color: "04-SmallColor.png",
    alt: "graphic design, logo, design, art, lifestyle",
    width: 296,
    height: imageHeight,
    active: false,
  },
  {
    id: 5,
    black: "05-SmallBlack.png",
    color: "05-SmallColor.png",
    alt: "ad, magazine, print, adver#sement, art, real estate",
    width: 478,
    height: imageHeight,
    active: false,
  },
  {
    id: 6,
    black: "06-SmallBlack.png",
    color: "06-SmallColor.png",
    alt: "web page, graphic design, crypto, NFT, currency",
    width: 461,
    height: imageHeight,
    active: false,
  },
  {
    id: 7,
    black: "07-SmallBlack.png",
    color: "07-SmallColor.png",
    alt: "Social Media Captions ideas",
    width: 756,
    height: imageHeight,
    active: false,
  },
  {
    id: 8,
    black: "08-SmallBlack.png",
    color: "08-SmallColor.png",
    alt: "web page, graphic design, homepage, simplicity, company",
    width: 643,
    height: imageHeight,
    active: false,
  },
  {
    id: 9,
    black: "09-SmallBlack.png",
    color: "09-SmallColor.png",
    alt: "salsa.rs",
    width: 643,
    height: imageHeight,
    active: false,
  },
  {
    id: 10,
    black: "10-SmallBlack.png",
    color: "10-SmallColor.png",
    alt: "YouTube thumbnail design",
    width: 756,
    height: imageHeight,
    active: false,
  },
  {
    id: 11,
    black: "11-SmallBlack.png",
    color: "11-SmallColor.png",
    alt: "web page, graphic design, homepage, music, app",
    width: 643,
    height: imageHeight,
    active: false,
  },
  {
    id: 12,
    black: "12-SmallBlack.png",
    color: "12-SmallColor.png",
    alt: "graphic design, logo, design, print, business card",
    width: 534,
    height: imageHeight,
    active: false,
  },
  {
    id: 13,
    black: "13-SmallBlack.png",
    color: "13-SmallColor.png",
    alt: "Instagram carousel posts",
    width: 1021,
    height: imageHeight,
    active: false,
  },
  {
    id: 14,
    black: "14-SmallBlack.png",
    color: "14-SmallColor.png",
    alt: "Copywriting sample",
    width: 735,
    height: imageHeight,
    active: false,
  },
  {
    id: 15,
    black: "15-SmallBlack.png",
    color: "15-SmallColor.png",
    alt: "web page, graphic design, homepage, style",
    width: 637,
    height: imageHeight,
    active: false,
  },
  {
    id: 16,
    black: "16-SmallBlack.png",
    color: "16-SmallColor.png",
    alt: "magazine, print, art, graphic design, nature, guide",
    width: 575,
    height: imageHeight,
    active: false,
  },
  {
    id: 17,
    black: "17-SmallBlack.png",
    color: "17-SmallColor.png",
    alt: "inpaso.net",
    width: 641,
    height: imageHeight,
    active: false,
  },
  {
    id: 18,
    black: "18-SmallBlack.png",
    color: "18-SmallColor.png",
    alt: "YouTube thumbnail design",
    width: 748,
    height: imageHeight,
    active: false,
  },
];
