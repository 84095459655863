import React from "react";

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="35"
    viewBox="0 0 49 35"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5947 0C31.719 0 37.849 4.25715 40.5795 10.3663H35.5329C33.2019 6.79958 29.1736 4.44271 24.5947 4.44271C17.4153 4.44271 11.5892 10.2371 11.5378 17.4044H7.09498C7.14645 7.78339 14.9616 0 24.5947 0ZM8.60891 24.6313C11.3389 30.7418 17.4696 35 24.5947 35C34.2597 35 42.0947 27.165 42.0947 17.5L42.0947 17.4747H37.652L37.652 17.5C37.652 24.7113 31.8061 30.5573 24.5947 30.5573C20.0149 30.5573 15.9858 28.1994 13.6551 24.6313H8.60891Z"
      fill="#5F5F5F"
    />
    <rect
      x="49"
      y="15.1055"
      width="4.44271"
      height="48.9628"
      transform="rotate(90 49 15.1055)"
      fill="#C3D400"
    />
  </svg>
);

export default Logo;
